import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home/Home.vue')
  },
  {
    path: '/face',
    name: 'face',
    component: () => import('../views/Face/Face.vue')
  },

  {
    path: '/faceresult',
    name: 'faceresult',
    component: () => import('../views/FaceResult/FaceResult.vue'),
  }, {
    path: '/faceFailed',
    name: 'faceFailed',
    component: () => import('../views/FaceFailed/index.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 如果当前路由是/faceresult 且目标路由是/faceFailed，则取消导航
  if (from.path === '/faceresult' && to.path === '/faceFailed') {
    return;
  }
  next();
});
export default router
